// DOM-based Routing
// Based on http://goo.gl/EUTi53 by Paul Irish
(function($) {
    var Sage = {
        'common': {
            init: function () {
                // Init Foundation
                $(document).foundation();

                // -----------
                // HEADER MENU
                // -----------
                var menu = $('[data-menu]');
                var menuToggle = $('[data-menu-toggle]');
                var menuIcon = menuToggle.find('i');
                var prevIcon;

                // On init, if menuToggle is visible, menu is closed (large <)
                // otherwise, is open (large)
                function checkMenuVisibility() {
                    menu.data('opened', !menuToggle.is(':visible'));
                }

                function updateMenuVisibility() {
                    menu.toggle(menu.data('opened')); // Toggle menu
                    menuIcon.attr('class', menuIcon.data(menu.data('opened') ? 'closed-class' : 'opened-class')); // Toggle icon
                }

                menuToggle.click(function (e) {
                    e.preventDefault();

                    menu.data('opened', !menu.data('opened'));
                    updateMenuVisibility();
                });

                $(window).resize(function () {
                    checkMenuVisibility();
                    updateMenuVisibility();
                });

                checkMenuVisibility();
                updateMenuVisibility();

                // Image keep ratio
                $(window).resize(function () {
                    $('[data-keep-ratio]').each(function (i, el) {
                        var opts = $(el).data('keep-ratio');
                        $(el).height(($(el).width() * opts[1]) / opts[0]);
                    });
                }).resize();

                // Image galleries
                $('[data-media-popup]').magnificPopup({ type:'image' });
                $('[data-media-gallery]').each(function () {
                    $(this).magnificPopup({
                        delegate: 'a',
                        type: 'image',
                        image: {
                            titleSrc: function(item) {
                                var author = item.el.data('author');

                                if (author.length) {
                                    return item.el.attr('title') +' <small class="images-gallery__author">FOTO: '+ author +'</small>';
                                } else {
                                    return item.el.attr('title');
                                }
                            }
                        },
                        gallery: {
                            enabled: true,
                        }
                    });
                });

                // Filters
                $('[data-filter]').each(function (i, el) {
                    var filterGroup = $(el).data('filter');

                    $(el).find('[data-filter-value]').click(function (e) {
                        var value = $(this).data('filter-value').toString();

                        e.preventDefault();

                        $(el).find('.filters__item').removeClass('filters__item_selected');
                        $(this).closest('.filters__item').addClass('filters__item_selected');

                        $('[data-filter-items="'+ filterGroup +'"] [data-filter-values]').each(function (i, el) {
                            var values = $(el).data('filter-values').toString().split(',');
                            $(el).toggle(value === "" || values.indexOf(value) >= 0);
                        });
                    });
                });
            },
            finalize: function () {},
        },
        'page_template_template_index': {
            init: function () {
                console.log('abc');
                // Carousel
                $('[data-carousel]').slick({
                    autoplaySpeed: 3000,
                    autoplay: true,
                    arrows: false,
                    infinite: true,
                });
            },
            finalize: function () {},
        },
        'about_us': {
            init: function () {},
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery);
